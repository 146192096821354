import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';

class FilterClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilter: false,
      animIsFinished: true,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  // Open/Close filter
  toggleFilter = (openFilter, animIsFinished) => {
    this.setState({ openFilter: !openFilter, animIsFinished: false });
    setTimeout(() => {
      this.setState({ animIsFinished: true });
    }, 400);
  };

  // Animate Link
  animLinkIn = event => {
    event.target.classList.add('in');
  };

  animLinkOut = event => {
    event.target.classList.remove('in');
    event.target.classList.add('out');

    setTimeout(() => {
      event.target.classList.remove('out');
    }, 1000);
  };

  handleKeyDown(e) {
    // check keys if you want
    if (e.keyCode == 13) {
      this.toggleFilter(this.state.openFilter, this.state.animIsFinished);
    }
  }

  render() {
    const { clients, updateFilterClients, selectedClient, intl } = this.props;
    return (
      <>
        <div
          className={`container client-filter columns is-justify-content-flex-end ${
            this.state.animIsFinished ? 'anim-is-finished' : ''
          } ${this.state.openFilter ? 'is-active' : ''}`}
          aria-expanded={this.state.openFilter}
          aria-haspopup="true"
        >
          <div
            className="is-mobile columns is-align-items-center is-justify-content-space-between is-text-indication is-uppercase px-4 mb-6"
            tabIndex="0"
            id="buttonclient"
            onClick={() =>
              this.toggleFilter(
                this.state.openFilter,
                this.state.animIsFinished
              )
            }
            aria-label={`${intl.formatMessage({
              id: 'client_label',
            })}`}
            onKeyUp={this.handleKeyDown}
          >
            <span>
              {selectedClient !== 'All' && selectedClient}{' '}
              {selectedClient === 'All' && 'Client'}
            </span>{' '}
            <span className="icon-arrow-down"></span>
          </div>
        </div>
        <div className="client-filter-block" hidden={!this.state.openFilter}>
          <a
            href="#"
            className="close-filter is-hidden-tablet"
            onClick={() =>
              this.toggleFilter(
                this.state.openFilter,
                this.state.animIsFinished
              )
            }
          >
            <span className="icon-cross"></span>
          </a>
          <div className="bkg-color2 p-6 is-text-indication">
            <ul className="listing">
              <li>
                <a href="#worklist" className="skip-links">
                  <FormattedMessage id="work.skip_clients" />
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={`link`}
                  onClick={e => {
                    e.preventDefault();
                    updateFilterClients(e, 'All');
                    this.toggleFilter(
                      this.state.openFilter,
                      this.state.animIsFinished
                    );
                  }}
                  onMouseEnter={e => this.animLinkIn(e)}
                  onMouseLeave={e => this.animLinkOut(e)}
                >
                  All
                </a>
              </li>
              {clients
                ?.filter(client => client.page_projet)
                ?.map((client, i) => (
                  <li key={i}>
                    <a
                      href="#"
                      className={`link ${
                        selectedClient === client.label ? 'is-active' : ''
                      }`}
                      onClick={e => {
                        e.preventDefault();
                        updateFilterClients(e, client.label);
                        this.toggleFilter(
                          this.state.openFilter,
                          this.state.animIsFinished
                        );
                      }}
                      onMouseEnter={e => this.animLinkIn(e)}
                      onMouseLeave={e => this.animLinkOut(e)}
                    >
                      {client.label}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          <a
            href="#buttonclient"
            onKeyDown={e => this.handleKeyDown(e)}
            className="skip-links"
          >
            <FormattedMessage id="work.button_clients" />
          </a>
        </div>
      </>
    );
  }
}

export default injectIntl(FilterClients);
