import React from 'react';
import FilterClients from './filterClients';

function Filters({ clients, selectedClient, updateFilterClients }) {
  return (
    <section className="works-filter columns is-flex-direction-column is-align-items-flex-end">
      <FilterClients
        clients={clients}
        selectedClient={selectedClient}
        updateFilterClients={updateFilterClients}
      />
    </section>
  );
}

export default Filters;
