import React, { useState, useEffect } from 'react';
import { Link, FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function worksList(props) {
  const { projects, selectedClient, intl } = props;

  // State for the list
  const [list, setList] = useState([...projects.slice(0, 5)]);

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(projects.length > 5);

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < projects.length;
      const nextResults = isMore
        ? projects.slice(currentLength, currentLength + 6)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]); //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < projects.length;
    setHasMore(isMore);
  }, [list]); //eslint-disable-line

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const handleKeyDown = e => {
    if (e.keyCode == 13) {
      handleLoadMore();
    }
  };

  // Animate Link arrow
  function animArrowIn(event) {
    event.target.classList.add('in');
  }

  function animArrowOut(event) {
    event.target.classList.remove('in');
    event.target.classList.add('out');

    setTimeout(function () {
      event.target.classList.remove('out');
    }, 1000);
  }

  return (
    <section className="container works-list pb-6" id="worklist">
      <div className="columns is-flex-wrap-wrap is-justify-content-space-between">
        {selectedClient === 'All' &&
          list?.map((project, i) => (
            <Link
              key={i}
              to={`/${project.node.seo.slug}`}
              className={`column is-clickable ${
                i === 0 && selectedClient !== project.node.client?.label
                  ? 'is-large'
                  : ''
              } ${
                selectedClient === project.node.client?.label ||
                selectedClient === 'All'
                  ? 'item-show'
                  : 'item-hide'
              }`}
            >
              <GatsbyImage
                as="figure"
                className={`is-fullwidth-image image ${
                  i === 0 && selectedClient !== project.node.client?.label
                    ? ''
                    : 'mb-4'
                }`}
                image={getImage(project.node.MainPlaceholderVideo?.visuel)}
                alt={
                  project.node.MainPlaceholderVideo?.visuel?.description || ''
                }
              />
              <div className="content">
                <div>
                  <p
                    className={`subtitle  mb-5 ${
                      i === 0 && selectedClient !== project.node.client?.label
                        ? 'is-2'
                        : 'is-4'
                    }`}
                  >
                    {project.node.client?.label}
                  </p>
                  <h3
                    className={`title ${
                      i === 0 && selectedClient !== project.node.client?.label
                        ? 'is-1'
                        : 'is-2'
                    }`}
                  >
                    {project.node.title}
                  </h3>
                  {i === 0 && selectedClient !== project.node.client?.label && (
                    <div
                      className="has-text-white mb-4"
                      dangerouslySetInnerHTML={{
                        __html:
                          project.node.catchphrase?.childMarkdownRemark?.html,
                      }}
                    />
                  )}
                </div>
                <div>
                  <span
                    className="button is-transparent is-uppercase"
                    aria-label={`${intl.formatMessage({
                      id: 'see_case',
                    })} ${project.node.title}`}
                  >
                    <FormattedMessage id="see_case" />
                  </span>
                </div>
              </div>
            </Link>
          ))}

        {selectedClient !== 'All' &&
          projects?.map((project, i) => (
            <Link
              key={i}
              to={`/${project.node.seo.slug}`}
              className={`column is-clickable ${
                i === 0 && selectedClient !== project.node.client?.label
                  ? 'is-large'
                  : ''
              } ${
                selectedClient === project.node.client?.label ||
                selectedClient === 'All'
                  ? 'item-show'
                  : 'item-hide'
              }`}
            >
              <GatsbyImage
                as="figure"
                className={`is-fullwidth-image image ${
                  i === 0 && selectedClient !== project.node.client?.label
                    ? ''
                    : 'mb-4'
                }`}
                image={getImage(project.node.MainPlaceholderVideo?.visuel)}
                alt={
                  project.node.MainPlaceholderVideo?.visuel?.description || ''
                }
              />
              <div className="content">
                <div>
                  <p
                    className={`subtitle  mb-5 ${
                      i === 0 && selectedClient !== project.node.client?.label
                        ? 'is-2'
                        : 'is-4'
                    }`}
                  >
                    {project.node.client?.label}
                  </p>
                  <h3
                    className={`title ${
                      i === 0 && selectedClient !== project.node.client?.label
                        ? 'is-1'
                        : 'is-2'
                    }`}
                  >
                    {project.node.title}
                  </h3>
                  {i === 0 && selectedClient !== project.node.client?.label && (
                    <div
                      className="has-text-white mb-4"
                      dangerouslySetInnerHTML={{
                        __html:
                          project.node.catchphrase?.childMarkdownRemark?.html,
                      }}
                    />
                  )}
                </div>
                <div>
                  <span
                    className="button is-transparent is-uppercase"
                    aria-label={`${intl.formatMessage({
                      id: 'see_case',
                    })} ${project.node.title}`}
                  >
                    <FormattedMessage id="see_case" />
                  </span>
                </div>
              </div>
            </Link>
          ))}
      </div>
      {hasMore && selectedClient === 'All' ? (
        <div className="wrapper-button-more">
          <a
            tabIndex="0"
            className="link go-bottom"
            onClick={handleLoadMore}
            onKeyUp={e => handleKeyDown(e)}
            onMouseEnter={e => animArrowIn(e)}
            onMouseLeave={e => animArrowOut(e)}
            aria-label={`${intl.formatMessage({
              id: 'load_more_label',
            })} `}
          >
            <FormattedMessage id="load_more" />{' '}
            <span className="icon-arrow-down"></span>
          </a>
        </div>
      ) : (
        ''
      )}
    </section>
  );
}

export default injectIntl(worksList);
