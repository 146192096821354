import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroTitle from '../components/heroTitle';
import Filters from '../components/worksList/filters';
import WorksList from '../components/worksList/worksList';
import BackToTop from '../components/navigation/backToTop';

class WorkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: 'All',
    };
  }

  // Filter clients
  updateFilterClients = (event, selectedClient) => {
    event.preventDefault();
    this.setState({ selectedClient });
  };

  render() {
    const { intl, data } = this.props;
    const projectsList = data.allContentfulPageProjet.edges;
    const clientsList = data.allContentfulClient.nodes;

    return (
      <Layout
        headerCssClass="bkg-color3"
        mainCssClass="projects-list bkg-color3"
        activeMenuLink="work"
      >
        <SEO title="Work" titleOnglet="Work" pageLink="work" />

        {/* HeroTitle */}
        <HeroTitle title="Work" />

        {/* Filters */}
        <Filters
          clients={clientsList}
          selectedClient={this.state.selectedClient}
          updateFilterClients={this.updateFilterClients}
        />

        {/* WorkList */}
        <WorksList
          projects={projectsList}
          selectedClient={this.state.selectedClient}
        />

        {/* BackToTop */}
        <BackToTop />
      </Layout>
    );
  }
}

export const query = graphql`
  query ContentfulProjectsList($language: String!) {
    allContentfulPageProjet(
      sort: { fields: publishedAt, order: DESC }
      filter: { node_locale: { eq: $language } }
    ) {
      edges {
        node {
          id
          seo {
            slug
          }
          title
          client {
            id
            label
          }
          MainPlaceholderVideo {
            visuel {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              description
            }
          }
          catchphrase {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulClient(
      filter: { node_locale: { eq: "fr" } }
      sort: { fields: label, order: ASC }
    ) {
      nodes {
        label
        id
        page_projet {
          id
        }
      }
    }
  }
`;

export default injectIntl(WorkList);
